<template>
  <b-modal size="xl" ref="form-edit-modal" hide-footer>

    <ErrorPopup
      :error="error_popup_message"
    />

    <v-row style="margin-top: 22px !important;">
      <v-col md="6">

        <memlist-select-input 
          id="type"
          name="type"
          :title="$t('FORM.FORM_RULE_TYPE')"
          layout="vertical"
          validation_type="TEXT"

          v-model="local_item.type"
          :items="type_options"
          :placeholder="$t('FORM.FORM_RULE_TYPE')"
          :required="true"
          :invalid_text="''"
          :info_text="''"
          @update:value="update_type"
        />

      </v-col>
    </v-row>

    <FormRuleCompanyEditor
      v-if="local_item.type === 'ASSIGN_COMPANY'"
      :item="local_item"
      @change="change"
    />
    
    <FormRuleMembershipEditor
      v-if="local_item.type === 'ASSIGN_MEMBERSHIP'"
      :item="local_item"
      @change="change"
    />

    <FormRuleAttributeEditor
      v-if="local_item.type === 'ASSIGN_ATTRIBUTE'"
      :item="local_item"
      @change="change"
    />

    <p class="mt-8"></p>

    <v-row>
      <v-col>
        <RightModalSaveAndCloseButtons
          :text="$t('COMMON.SAVE')"
          :spin="true"
          @clicked="on_save_clicked"
          @close="$refs['form-edit-modal'].hide()"
          ref="saveButton" />
      </v-col>
    </v-row>


  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';


import ErrorPopup from '@/view/components/ErrorPopup.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


import FormRuleCompanyEditor from '@/view/pages/ml/forms/rules/FormRuleCompanyEditor.vue';
import FormRuleMembershipEditor from '@/view/pages/ml/forms/rules/FormRuleMembershipEditor.vue';
import FormRuleAttributeEditor from '@/view/pages/ml/forms/rules/FormRuleAttributeEditor.vue';

import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';


export default {
  name: 'FormsPageEditorModal',
  mixins: [ toasts ],
  props: ['item','form'],
  emits: ['create', 'update'],
  components: {
    TiptapVuetify,
    ErrorPopup,
    RightModalSaveAndCloseButtons,
    FormRuleCompanyEditor,
    FormRuleMembershipEditor,
    FormRuleAttributeEditor
  },

  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    type_options() {

      let options = [
        { text: this.$t('FORM.FORM_RULE_TYPES.ASSIGN_COMPANY'), value: 'ASSIGN_COMPANY' },
        { text: this.$t('FORM.FORM_RULE_TYPES.ASSIGN_MEMBERSHIP'), value: 'ASSIGN_MEMBERSHIP' },
        { text: this.$t('FORM.FORM_RULE_TYPES.ASSIGN_SHOP_ITEM'), value: 'ASSIGN_SHOP_ITEM' },
        { text: this.$t('FORM.FORM_RULE_TYPES.ASSIGN_ATTRIBUTE'), value: 'ASSIGN_ATTRIBUTE' },
      ]

      options = options.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      return options;
    },
    
  },
  mounted() {
    
    if (this.item) {
      this.local_item = { ...this.item }

      if (!this.local_item.groups) {
        this.local_item = {
          groups: [],
          company_ids: [],
          shop_item_ids: []
        }
      }
    }
  },
  data() {
    return {
      spinning: false,
      show_error: false,
      is_form_valid: false,
      error_popup_message: null,
      local_item: {
        groups: [],
        company_ids: [],
        shop_item_ids: []
      },
      force_validate: 0,
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  watch: {

    item: {
      handler(newValue) {
        this.local_item = { ...newValue };
      },
      deep: true
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    update_type(field, value) {

      console.log('update_type', value)
      this.local_item.type = value;

      this.local_item.groups = [];
      this.local_item.company_id = null;
      this.local_item.shop_item_id = null;
      
      this.local_item = { ...this.local_item };
    },

    page_created(page) {
      this.$emit('created', page);
    },

    change(item) {
      this.local_item = { ...item };
    },

    update_form(field_name, value) {
      this.local_item[field_name] = value;
      this.local_item = { ...this.local_item };
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_save_clicked() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(async ()=>{

        try {
          if (this.is_form_valid) {
            this.spinning = false;

            if (this.local_item.id) {
              this.$emit('update', this.local_item);
            }
            else {
              this.$emit('create', this.local_item);
            }
            
          }
          else {
            this.show_error = true; 
          }
        }
        catch (err) {
          console.error('on_save_clicked', err);
        }

        setTimeout(()=>{
          this.spinning = false;

          this.$refs['saveButton'].stop();
        }, 500);
        
      });
    },

    // add any methods we need
    show() {
      this.$refs['form-edit-modal'].show();
    },

    hide() {
      this.$refs['form-edit-modal'].hide();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
